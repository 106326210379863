import {

    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,

} from "@mui/material";
const WarningDialog = ({ handleClose, title, message, handleConfirm, open }) => {
    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Go Back</Button>

            <Button onClick={handleConfirm} >
                Yes
            </Button>
        </DialogActions>
    </Dialog>
};
export default WarningDialog;