import axios from "axios";
import { Baseurl } from "../utils/constants";

const ApiClient = axios.create({
    baseURL: Baseurl,
    headers: { 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDU3YTNkNjEwYjI0ZTAxMzk1NWE1YjUiLCJpYXQiOjE2ODQ5Mjk4Nzl9.jnG3zD6x2mmZUM1J4GJjSL_vmS2WWMpTNoApG4cDLcc' }
});



export default ApiClient
