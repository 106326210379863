
import '../components/chat/helpdesk.css';
import QueryUserCard from "../components/chat/queryusercard";
import QueryCard from "../components/chat/querycard";
import { useEffect, useState } from 'react';
import ApiClient from '../app/axiosClient';

const Helpdesk = () => {
    const [queries, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedQuery, setSelectedQuery] = useState(null)
    async function getContest() {
        ApiClient.get(`/query`).then((e) => {
            console.log(e.data.data);
            setData(e.data.data);
            setLoading(false);
            setSelectedQuery(queries[0])
        });
    }
    useEffect(() => {
        getContest();
        return () => {
        };
    }, [,]);

    return (
        loading ? <>Loading</> : <div>
            <div className="row p-0 m-0">
                <div className="col-md-3 col-12 chat-side-menu bg-white m-0 p-2 rounded">
                    <h3>Queries</h3>
                    <div className="chat-user-list">
                        <br />

                        {queries.map((query, index) => <QueryUserCard
                            name={query.subject}
                            id={query._id}
                            selectedQueryId={selectedQuery?._id}
                            time={new Date(query.updatedAt).toDateString()}
                            key={index}
                            onClick={() => setSelectedQuery(query)}
                        />)}



                    </div>
                </div>
                {selectedQuery == null ? null : <div className="col-md-9 col-12 chat-main-content p-0 bg-white rounded" >
                    <div className="chat-top-bar m-0 row  justify-content-between bg-white">
                        <div className="col chat-top-bar bg-white">
                            <div className="avatar">

                            </div>
                            <div className="m-0 mx-3 text-right">
                                <h3 className="mb-3"><span style={{
                                    fontSize: "13px",
                                    marginRight: "10px"
                                }}>QUERY ID: </span>{selectedQuery._id.toUpperCase().substring(0, 8)}</h3>
                                <span className="text-muted text-small  my-3"> {new Date(selectedQuery.createdAt).toLocaleDateString()}</span>
                            </div>
                        </div>

                    </div>
                    <div className="chat-main-box">
                        <div className="chat-section p-3">
                            <QueryCard query={selectedQuery} onResponse={(val) => {
                                setLoading(val);
                                if (val === false) {
                                    getContest()
                                    setSelectedQuery(null)
                                }
                            }} />

                        </div>
                    </div>
                </div>
                } </div>
        </div>
    );
};

export default Helpdesk
