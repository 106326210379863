import {
    Avatar,
    Button,
    Container,
    createTheme,
    Grid,
    ThemeProvider,
    Typography,
} from "@mui/material";

import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ApiClient from "../app/axiosClient";

import WarningDialog from "../components/warning-dialog/WarningDialog";



const keyValueTheme = createTheme({
    typography: {
        button: {
            fontSize: "1rem",
        },

        h3: {
            margin: "10px",
            fontSize: "14px",
            color: "#646464",
        },
        h2: {
            margin: "10px",
            fontSize: "16px",
            color: "#f2f2f2",
        },
    },
});
export default function User() {
    const [deleteUserOpen, setDeleteUserOpen] = React.useState(false);
    const params = useParams();
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true);

    async function getUserData() {
        ApiClient.get(`/user/${params.id}`).then((e) => {
            console.log("SENDNND");
            console.log(e.data.data);
            setLoading(false)
            setUser(e.data.data)
        })
    }
    useEffect(() => {
        return () => {
            getUserData()
        }
    },[setUser,setLoading])
    async function toggleUserStatus() {
        setDeleteUserOpen(false);
        setLoading(true);
        ApiClient.post(`/customer/${user.isActive ? 'deactivate' : 'activate'}/${params.id}`).then((e) => {
            console.log(e.data);
        })
        getUserData()
    }
    return (
        loading === true ? <h1>Loading</h1> :
            <div className="px-3" >
                < Grid item xs={12} sm={6} >
                    <h1>User</h1>
                </ Grid>

                <Grid container rowSpacing={2} sx={{ display: "flex" }}>
                    <Grid
                        item
                        xs={12}
                        md={3}
                        sx={{
                            margin: "30px",
                        }}
                    >
                        <Avatar
                            src={user.image}
                            sx={{
                                width: "130px",
                                height: "130px",
                            }}
                        />
                        <Typography sx={{ margin: "10px", fontSize: "27px" }}>
                            {user.name}
                        </Typography>
                        <Typography sx={{ margin: "10px", fontSize: "12px" }}>
                            {user.email}
                        </Typography>
                        <Typography sx={{ margin: "10px", fontSize: "12px" }}>
                            {user.phone}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ThemeProvider theme={keyValueTheme}>
                            <Container
                                container="true"
                                xs={12}
                                sx={{
                                    display: "flex",
                                }}
                            >
                                <Typography variant="h3">Wallet Balance:</Typography>
                                <Typography variant="p" style={{
                                    display: "flex", alignItems: "center"
                                }} className="">₹ {user?.wallet?.amount ?? 0}</Typography>
                                <Link to={`/wallet/${user._id}`}>   <Button variant="text"
                                    size={"small"}
                                    sx={{
                                        height: "30px",
                                        marginTop: "6px",
                                        // marginBottom: "auto",
                                        marginLeft: "10px",
                                    }}
                                >
                                    History
                                </Button></Link>
                            </Container>
                            <Container

                                sx={{
                                    display: "flex",
                                }}
                            >
                                <Typography variant="h3">Created At:</Typography>
                                <Typography variant="h2">{new Date(user.createdAt).toDateString()}</Typography>
                            </Container>
                            <Container
                                sx={{
                                    display: "flex",
                                }}
                            >
                                <Typography variant="h3">Account Status:</Typography>
                                <Typography style={{
                                    fontWeight: "bold",
                                    color: user.isActive ? "green !important" : "red !important",
                                }} variant="h2">{user.isActive === false ? 'InActive' : 'Active'}</Typography>
                            </Container>
                            <Container
                                container="true"
                                xs={12}
                                sx={{
                                    display: "flex",
                                }}
                            >
                                <Typography variant="h3">Last Login:</Typography>
                                <Typography variant="h2">{new Date(user.createdAt).toLocaleString()}</Typography>
                            </Container>
                        </ThemeProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <h3>Actions</h3>

                        <WarningDialog open={deleteUserOpen} handleClose={() => setDeleteUserOpen(false)} handleConfirm={toggleUserStatus} message={user.isActive ? 'Are you sure you want to Deactivate this user?' : "Are you sure you want to Activate this user?"} title={user.isActive ? 'Deactivate Account?' : 'Activate Account?'} />
                        <Button
                            onClick={() => setDeleteUserOpen(true)}
                            variant="outlined" sx={{
                                margin: "10px"
                            }} color="warning">{user.isActive ? 'Deactivate Account' : 'Activate Account'}</Button>

                        {/* <WarningDialog open={deactivateUserOpen} handleClose={() => { }} handleConfi={() => deactivateUser()} message="Are you sure you want to delete this user?" title="Delete User" /> */}
                    </Grid>
                </Grid>
            </div >
    );
}
