import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./sidebar.scss";
import Logo from "../../assets/images/logo.png";
import sidebar_items from "../../assets/JsonData/sidebar_routes.json";
import { useEffect } from "react";


const SidebarItem = (props) => {


  return (
    <div className="sidebar__item">
      <div className={`sidebar__item-inner ${props.active && 'active'}`}>
        <i className={props.icon}></i>
        <span>{props.title}</span>
      </div>
    </div>
  );
};
const Sidebar = () => {

  const [activeItem, setActiveItem] = useState(0)
  useEffect(() => {
    return () => {
      const d = sidebar_items.findIndex((item) => item.route === window.location.pathname);
      setActiveItem(d ?? 0)
    }
  }, [
    setActiveItem])

  const [expanded, setExpanded] = useState(false)
  return (
    <nav className="text-white ">
      {["sm"].map((expand) => (
        <Navbar
          key={expand}
          expanded={expanded}
          expand={expand}
          bg="white"
          className="mb-3 sidebar app-navbar navbar-dar"
        >
          <Container fluid>

            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand} border-none`}
              onClick={() => {
                setExpanded(true)
                console.log("Click");
              }}
            >
              {/* <img
                src={SideMenu}
                color="white"
                width={"25px"}
                className="m-1 p-0 my-2"
                alt={"sidemenu"}
              /> */}
            </Navbar.Toggle>

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
              className="app-navbar"

            >

              <Offcanvas.Header closeButton onClick={() => setExpanded(false)}>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Grocery
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 app-navbar ">
                  <img src={Logo} className="p-3 text-center col-6" width={"120px"} alt="logo" />
                  {sidebar_items.map((item, index) => (
                    <Link to={item.route} key={index} onClick={() => {
                      setExpanded(false);
                      setActiveItem(index)
                    }}>
                      <SidebarItem
                        title={item.display_name}
                        icon={item.icon}
                        active={activeItem === index}
                        url={item.route}
                      />
                    </Link>
                  ))}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>

          </Container>
        </Navbar>


      ))
      }  </nav>
  );
}



export default Sidebar;
