import React from "react";
import "./button.css";
const Button = (props) => {
  return (
    <div>
      <button className={`btn`} type="submit">{props.content}</button>
    </div>
  );
};

export default Button;
