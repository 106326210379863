import React from 'react'

import './statuscard.css'

const StatusCard = props => {
    return (
        <div className='status-card' style={{
            backgroundColor: props.backgroundColor ?? "#09433A"
        }}>
            <div className="status-card__icon">
                <i className={props.icon}></i>
            </div>
            <div className="status-card__info">
                <h4 className="status-card__count text-white">{props.count}</h4>
                <span style={{
                    fontSize: "13px"
                }}>{props.title}</span>
            </div>
        </div>
    )
}

export default StatusCard
