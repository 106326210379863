import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import userProvider from '../repository/userRepo';

const initialState = {
    users: [],
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: 'string' | null,
    tempUsers: []

}
export const getUser = createAsyncThunk('/getAllUsers', async (limitTo) => {
    const data = await userProvider.getUsers(limitTo)
    console.log("data ", data);
    return data.data
})

export const userSlice = createSlice({
    name: 'userProvider',
    initialState,
    reducers: {
        getTheUsers: (state) => {

        },
        filterUsersByName: (state, action) => {
            state.users = state.tempUsers
            state.users = state.users.filter(function (user) {
                return user.name.toLowerCase().includes(action.payload.toLowerCase()) || user.uid.toLowerCase().includes(action.payload.toLowerCase());
            });
        },
        resetSearch: (state, action) => {
            state.users = state.tempUsers
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getUser.pending, (state) => {
            state.status = 2;
        }).addCase(getUser.fulfilled, (state, action) => {
            state.status = 3;
            state.tempUsers = action.payload;
            state.users = action.payload
        }).addCase(getUser.rejected, (state, action) => {
            state.status = 4
        })
    },
})
export const selectUser = (state) => state.userProvider.users
export const selectStatus = (state) => state.userProvider.status

export const { getTheUsers, filterUsersByName, resetSearch } = userSlice.actions

export default userSlice.reducer