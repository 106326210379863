import React from "react";

import "./topnav.css";

import { Link } from "react-router-dom";

import Dropdown from "../dropdown/Dropdown";

import user_menu from "../../assets/JsonData/user_menus.json";

const curr_user = {
    display_name: "Admin",
    image:
        "https://pixinvent.com/demo/vuexy-html-bootstrap-admin-template/app-assets/images/profile/user-uploads/user-04.jpg",
};

const renderUserToggle = (user) => (
    <div className="topnav__right-user">
        <div className="topnav__right-user__image">
            <img src={user.image} alt="" />
        </div>
        <div className="topnav__right-user__name">{user.display_name}</div>
    </div>
);

const renderUserMenu = (item, index) => (
    <Link to={item.url} key={index}>
        <div className="notification-item">
            <i className={item.icon}></i>
            <span>{item.content}</span>
        </div>
    </Link>
);

const Topnav = () => {
    return (
        <div className="topnav">
            <div className="topnav__search">
                <h4>Welcome Admin,</h4>
            </div>
            <div className="topnav__right">
                <div className="topnav__right-item">
                    {/* dropdown here */}
                    <Dropdown
                        customToggle={() => renderUserToggle(curr_user)}
                        contentData={user_menu}
                        renderItems={(item, index) => renderUserMenu(item, index)}
                    />
                </div>

            </div>
        </div>
    );
};

export default Topnav;
