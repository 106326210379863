import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Table from "../components/table/Table";
import ApiClient from "../app/axiosClient";
const customerTableHead = [
    "id",
    "Date & time",
    "Amount",
    "Transaction Type",
    "Ref",
    "Ending Balance",
];
export const renderHead = (item, index) => <th style={{ textAlign: "center" }} key={index}>{item}</th>;
const History = () => {
    const params = useParams()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true);

    async function getUserWallet() {
        console.log(params);
        ApiClient.get(`/wallet/log/${params.id}`).then((e) => {
            console.log(e.data.data);
            setData(e.data.data)
            setLoading(false)
        })
    }
    useEffect(() => {
        return () => {
            getUserWallet()
        }
    })

    const renderBody = (item, index) => (
        <tr key={index} style={{ textAlign: "center" }}>
            <td>{item._id}</td>
            <td>{new Date(item.createdAt).toLocaleString()}</td>
            <td>₹{item.amount}</td>
            <td style={{
                fontWeight: 600,
                color: item.type === 'C' ? 'green' : 'red'

            }}>{item.type === 'C' ? 'CREDIT' : 'DEBIT'}</td>

            <td>{item.message}</td>
            <td>{item.ref}</td>
        </tr>
    );
    return (
        loading ? <div>Loading...</div> : < div >

            <h2 className="page-header" ><span></span>Wallet History</h2>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body">
                            <Table
                                limit="10"
                                headData={customerTableHead}
                                renderHead={(item, index) => renderHead(item, index)}
                                bodyData={data}
                                renderBody={(item, index) => renderBody(item, index)}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
};
export default History;