import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import StatusCard from "../components/status-card/StatusCard";
import ApiClient from "../app/axiosClient";

const chartOptions = {
    series: [
        {
            name: "Online Customers",
            data: [40, 70, 20, 90, 36, 80, 30, 91, 60],
        },
        {
            name: "Store Customers",
            data: [40, 30, 70, 80, 40, 16, 40, 20, 51, 10],
        },
    ],
    options: {
        color: ["#6ab04c", "#2980b9"],
        chart: {
            background: "transparent",
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
        },
        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
            ],
        },
        legend: {
            position: "top",
        },
        grid: {
            show: false,
        },
    },
};









const Dashboard = () => {
    const themeReducer = useSelector((state) => state.ThemeReducer.mode);
    const [data, setData] = useState({
        userCount: 0,
        totalContests: 0,
        onGoingContests: 0,
        todayUserCount: 0,
        todayDepoists: 0,
        totalDepoists: 0,

    })
    useEffect(() => {

        getDashBoardData()
        return () => {
        }
    }, [])

    async function getDashBoardData() {
        console.log("Getting Data");
        const response = await ApiClient.get("/dashboard");
        console.log(response);
        setData({
            userCount: response.data.data.userCount,
            totalContests: response.data.data.totalContests,
            onGoingContests: response.data.data.onGoingContests,
            todayUserCount: response.data.data.todayUserCount,
            todayDepoists: `₹${response.data.data.todayDepoists}`,
            totalDepoists: `₹${response.data.data.totalDepoists}`
        })
    }
    return (
        <div className="m-3">
            <h2 className="page-header px-3 ">Dashboard</h2>
            <div className="row px-3 px-md-0">
                <div className="col-md-6 col-12">
                    <div className="row">



                        <div className="col-6" >
                            <StatusCard
                                icon={'bx bx-user'}
                                count={data.userCount}
                                title={'Total Users'}
                                backgroundColor="#400835"
                            />
                        </div>
                        <div className="col-6" >
                            <StatusCard
                                icon={'bx bx-user'}
                                count={data.todayUserCount}
                                title={'Today Registered'}
                                backgroundColor="#0D0634"
                            />
                        </div> <div className="col-6" >
                            <StatusCard
                                icon={'bx bx-user'}
                                count={data.onGoingContests}
                                title={'Ongoing Contest'}
                                backgroundColor={"#002F5F"}
                            />
                        </div> <div className="col-6" >
                            <StatusCard
                                icon={'bx bx-user'}
                                count={data.totalContests}
                                title={'Total Contests'}
                                backgroundColor="#063034"
                            />
                        </div>
                        <div className="col-6" >
                            <StatusCard
                                icon={'bx bx-user'}
                                count={data.todayDepoists}
                                title={'Today Depoists'}
                                backgroundColor="#5F1624"
                            />
                        </div>
                        <div className="col-6" >
                            <StatusCard
                                icon={'bx bx-user'}
                                count={data.totalDepoists}
                                title={'Total Depoists'}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="card full-height">

                        <Chart
                            options={
                                themeReducer === "theme-mode-dark"
                                    ? {
                                        ...chartOptions.options,
                                        theme: { mode: "dark" },
                                    }
                                    : {
                                        ...chartOptions.options,
                                        theme: { mode: "light" },
                                    }
                            }
                            series={chartOptions.series}
                            type="line"
                            height="100%"
                        />
                    </div>
                </div>



            </div>
        </div >
    );
};

export default Dashboard;
