

const Profile = () => {
    return <div className="m-3 m-md-3">
        <h4>Profile</h4>
        <h6 className="sub-title mt-5">General Information</h6>
        <div className="row">
            <div className="col-md-4 col-12">
                <input
                    className="textfield"
                    placeholder="App Version"
                    required
                    name="joiningFee"
                />

            </div>
            <div className="col-md-4">


            </div>
            <div className="col"></div>
            <div className="col-md-4">

            </div>
            <div className="col-md-4 col-12 ">

            </div>
        </div>
        <h6 className="sub-title mt-5">Change Password</h6>
        <div className="row">
            <div className="col-md-3 col-12">
                <input
                    className="textfield"
                    placeholder="Current Password"
                    required
                    name="joiningFee"
                />
            </div>
            <div className="col-md-3 col-12">
                <input
                    className="textfield"
                    placeholder="New Password"
                    required
                    name="joiningFee"
                />
            </div>
            <div className="col-md-3 col-12">
                <input
                    className="textfield"
                    placeholder="Confirm New Password"
                    required
                    name="joiningFee"
                />
            </div>
            <div className="col-md-3 col-12 mt-4">


            </div>
        </div>
    </div>;
};
export default Profile;
