import React from 'react'

export default function QueryUserCard({ name, time, onClick, id, selectedQueryId }) {
    return (
        <div className="chat-user-card mb-4 p-2" onClick={onClick} style={{
            backgroundColor: id === selectedQueryId ? "#f2f3f2" : null,
            borderRadius: "20px"
        }}>
            <h6 className="m-0 ">
                {name}
            </h6>
            <span className='text-muted text-small mt-0' style={{ 'fontSize': '13px' }}>

                {time}
            </span>

        </div>
    )
}