import Button from "../components/button/button";
// import "../components/topnav/topnav.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ApiClient from "../app/axiosClient";


const NotificationSection = () => {
    const router = useNavigate();
    const [loading, setLoading] = useState(false);
    const [dis, ] = useState([
        {
            key: 1,
            start: 1,
            end: null,
            amount: null,
        },
    ]);
    const onSubmit = (data) => {
        data.preventDefault();
        if (dis.at(0).amount === null || dis.at(0).end === null || dis.at(0).end === '' || dis.at(0).amount === null) {
            alert("Please Provide correct Prize Distribution.");
            return;
        }
        const body = {
            name: data.target.name.value,
            joiningFee: data.target.joiningFee.value,
            startTime: data.target.startTime.value,
            endTime: data.target.endTime.value,
            limit: data.target.limit.value,
            prizeDistribution: dis
        };
        if (body.startTime > body.endTime) {
            console.log("Incorrect start time");
            return;
        }
        setLoading(true);
        ApiClient.post("/contest/add", body).then((e) => {
            console.log(e.data);
        });
        setLoading(false);
        router(-1);
        console.log(body);
    };

    return (
        loading ? <>Loading</> : <div>
            <div className="row justify-content-between px-3 mt-5">
                <h2 className="col-5 page-header title ">Notification</h2>
                <div
                    className="col-2 text-end"
                    onClick={() => {
                        router(-1);
                    }}
                >
                </div>
            </div>
            <div className="col-12">
                <form onSubmit={onSubmit}>
                    <div className="">
                        <div className="col-md-6 col-12 ">
                            <label htmlFor="">Title</label>
                            <input
                                className="textfield"
                                type="text"
                                placeholder="Enter name"
                                name="name"
                                required
                            />
                        </div>
                        <div className="flex">
                            <div className="col-md-6">
                                <label htmlFor="">Message</label>
                                <textarea
                                    className="textfield"
                                    type="number"
                                    placeholder="message"
                                    required
                                    name="joiningFee"
                                    rows={"3"}
                                />
                            </div>
                        </div>
                        <input type={"file"} />

                        <Button className="col-8 mt-6" content="Sent Notification" />
                    </div>
                </form>
            </div>
        </div>
    );
};
export default NotificationSection;
