import Button from "../components/button/button";
import "../components/topnav/topnav.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiClient from "../app/axiosClient";

const EditContest = () => {
    const router = useNavigate();
    const [loading, setLoading] = useState(true)
    const params = useParams();
    const [data, setData] = useState({})
    useEffect(() => {

        getContestDetails()
        return () => {
        }
    })
    const getContestDetails = async () => {
        const res = await ApiClient.get(`/contest/${params.id}`);
        const tempData = {
            name: res.data.data.name,
            joiningFee: res.data.data.joiningFee,
            startTime: res.data.data.startTime.slice(0, 10),
            endTime: res.data.data.endTime.slice(0, 10),
            limit: res.data.data.limit,
        }
        setLoading(false)
        setData(tempData);
        console.log(data);
    }


    const onSubmit = (data) => {
        data.preventDefault()
        const body = {
            name: data.target.name.value,
            joiningFee: data.target.joiningFee.value,
            startTime: data.target.startTime.value,
            endTime: data.target.endTime.value,
            limit: data.target.limit.value,
        }
        if (body.startTime > body.endTime) {
            console.log("Incorrect start time");
            return;
        }
        setLoading(true)
        ApiClient.post(`/contest/update/${params.id}`, body).then((e) => {
            console.log(e.data);
        })
        setLoading(false)
        router(-1)
        console.log(body);
    }
    return (
        loading ? <>Loading</> : <div>
            <div className="row px-2  justify-content-between">
                <h2 className="col-7 page-header">Edit Contest</h2>
                <div
                    onClick={() => {
                        router(-1);
                    }}
                    className="col-3 text-end"
                >
                    <Button content="Back" />
                </div>
            </div>
            <div className="col-12">
                <form onSubmit={onSubmit}>
                    <div className="car">
                        <div className="col-md-6 col-12">
                            <label htmlFor="">Title</label>
                            <input defaultValue={data.name} className="textfield" type="text" placeholder="Enter name" name="name" required />
                        </div>
                        <div className="flex">
                            <div className="col-md-5 col-6">
                                <label htmlFor="">Joining Fee</label>
                                <input
                                    className="textfield "
                                    type="number"
                                    defaultValue={data.joiningFee}
                                    placeholder="Enter fee"
                                    required
                                    name="joiningFee"
                                />
                            </div>
                            <div className="col-md-3 col-6">
                                <label htmlFor="">Limit</label>
                                <input
                                    className="textfield"
                                    type="number"
                                    defaultValue={data.limit}
                                    placeholder="Enter limit"
                                    required
                                    name="limit"
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="col-md-4 col-6">
                                <label htmlFor="">Start Time</label>
                                <input
                                    defaultValue={data.startTime}
                                    className="textfield"
                                    type="date"
                                    placeholder="Enter fee"
                                    required
                                    name="startTime"
                                />
                            </div>
                            <div className="col-md-4 col-6">
                                <label htmlFor="">End Time</label>
                                <input
                                    defaultValue={data.endTime}

                                    className="textfield"
                                    type="date"
                                    placeholder="Enter limit"
                                    required
                                    name='endTime'
                                />
                            </div>
                        </div>

                        <Button className="col-8" content="Update contest" />
                    </div></form>
            </div>
        </div>
    );
};
export default EditContest;
