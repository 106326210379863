import axios from "axios"

import { Baseurl } from "../utils/constants";



const userProvider = {

    async getUsers(count) {


        console.log('<---- Getting users -----> ');

        const users = axios.get(`${Baseurl}/customers?page=0`, {
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzI2ZTA0ZGNlMmQyOGFkNWQzZGQ5ZDEiLCJpYXQiOjE2NjUyMDI3MzcsImV4cCI6MTY2NTgwNzUzN30.1vmCpcXx_p8Jyx2Xb-87C-SfzRPT012ySnFQ4i2O5lM'
            }
        })
            .then((e) => {
                console.log('<---- Getting users -----> ');
                console.log(e);
                let users = [];
                e.data.data.forEach((json) => {
                    users.push({
                        email: json['email'],
                        name: json['name'],
                        phone: json['phone'],
                        createdAt: json['createdAt'],
                        uid: json['_id'],
                        profileUrl: json['image'],

                    })
                });
                console.log('<---- Got Users -----> ');
                return users;
            }).catch((e) => { console.log(e); });
        return { data: users }
    }
}
export default userProvider;