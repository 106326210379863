import Table from "../components/table/Table";
import { AiFillEdit } from "react-icons/ai"
import Button from "../components/button/button";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiClient from "../app/axiosClient";

const contestTableHead = [
  "UID",
  "name",
  "Joining Fee",
  "Count",
  "Start Time",
  "End Time",
  "Actions",
];

export const renderHead = (item, index) => <th key={index}>{item}</th>;

export const renderBody = (item, index) => (
  <tr key={index}>
    <td>{item._id}</td>
    <td>{item.name}</td>
    <td>₹{item.joiningFee}</td>
    <td>{item.joined}</td>
    <td>{new Date(item.startTime).toDateString()}</td>
    <td>{new Date(item.endTime).toDateString()}</td>
    <td> <Link to={`/contest/edit/${item._id}`}><AiFillEdit /></Link></td>
  </tr >
);

const Contests = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true);

  async function getContest() {
    ApiClient.get(`/contest/all?page=0`).then((e) => {
      console.log(e.data.data);
      setData(e.data.data)
      setLoading(false)
    })
  }
  useEffect(() => {
    getContest()
    return () => {
    }
  }, [])

  return (
    loading ? <>Loading</> : <div className="p-md-3 p-3">
      <div className="row justify-content-between mt-3 px-md-0">
        <h2 className="col-2 page-header ">Contests</h2>
        <Link to={"/addContest"} className={"col-5 text-end"}>

          <Button className=" " content="Add Contest" />
        </Link>
      </div>
      <div className="row p-0 m-0">
        <div className="col-12">
          <div className="card">
            <div className="card__body">
              <Table
                limit="10"
                headData={contestTableHead}
                renderHead={(item, index) => renderHead(item, index)}
                bodyData={data}
                renderBody={(item, index) => renderBody(item, index)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contests;
