import React from 'react'

export default function PrizeDistribution({ start, end, handleChang }) {
    console.log(start);
    return (
        <div className="row  mx-2 flex">
            <div className="col-md-2 col-3">
                <label htmlFor="">Start</label>
                <input
                    className="textfield"
                    type="number"
                    placeholder="Enter "
                    required
                    readOnly
                    defaultValue={start}
                    name="start"

                />
            </div>
            <div className="col-md-2 col-4">
                <label htmlFor="">End</label>
                <input
                    className="textfield"
                    type="number"
                    placeholder="Enter "
                    required
                    name="end"
                    onChange={handleChang}
                />
            </div>
            <div className="col-md-2 col-4">
                <label htmlFor="">Amount</label>
                <input
                    className="textfield"
                    type="number"
                    placeholder="Enter "
                    required
                    name="amount"
                    onChange={handleChang}
                />
            </div>

        </div>
    )
}
