import ApiClient from "../../app/axiosClient";
import Button from "../button/button";

const QueryCard = ({ query, onResponse }) => {
  async function updateQuery(e) {
    onResponse(true)
    e.preventDefault();
    const response = e.target.response.value;
    console.log(response);
    const data = await ApiClient.post("/query/update", { id: query._id, response: response })
    console.log(data);
    onResponse(false)
  }
  return <div className="chat-card my-2">
    <div className="row">
      <div className="col-m-2 col-6">
        <h6 className="mx-2  mt-2  mb-0" style={{ 'fontSize': '13px', 'fontWeight': 400, 'color': 'grey' }}>Issue Type</h6>
        <h5 className="mx-2 mt-1 mb-0" style={{ 'fontSize': '16px', 'fontWeight': 500, 'color': 'black' }} >{query.subject}</h5>
      </div>
      <div className="col-3 ">
        <h6 className="mx-2 mt-2  mb-0" style={{ 'fontSize': '13px', 'fontWeight': 400, 'color': 'grey' }}>User</h6>
        <h5 className="mx-2 mt-1 mb-0" style={{ 'fontSize': '16px', 'fontWeight': 500, 'color': 'black' }} >{query.user.name}</h5>
      </div>
      <div className="col-4 mt-3">
        <h6 className="mx-2 mt-2 mb-0" style={{ 'fontSize': '13px', 'fontWeight': 400, 'color': 'grey' }}>Email</h6>
        <h5 className="mx-2 mt-1 mb-0" style={{ 'fontSize': '16px', 'fontWeight': 500, 'color': 'black' }} >{query.user.email}</h5>
      </div>
    </div>
    <div className="row">
      <div className="m-0 col-6">
        <h6 className="mx-2 mt-4 mb-0" style={{ 'fontSize': '13px', 'fontWeight': 400, 'color': 'grey' }}>Query</h6>
        <h5 className="mx-2 mt-1 mb-3" style={{ 'fontSize': '15px', 'fontWeight': 400, 'color': 'black' }}>{query.message}</h5>
      </div>
      <div className="m-0 col-6">
        <h6 className="mx-2 mt-4 mb-0" style={{ 'fontSize': '13px', 'fontWeight': 400, 'color': 'grey' }}>STATUS</h6>
        <h5 className="mx-2 mt-1 mb-3" style={{ 'fontSize': '15px', 'fontWeight': 500, 'color': query.status === "CLOSED" ? "green" : 'black' }}>{query.status}</h5>
      </div>
    </div>
    <br />

    <h6 className="mx-2 mt-4 mb-0" style={{ 'fontSize': '13px', 'fontWeight': 400, 'color': 'grey' }}>Response to the query</h6>
    {query.response != null ? <p className="mx-2 mt-1 mb-3">{query.response}</p> : <div>
      <form action="" onSubmit={updateQuery}>
        <textarea className="form-control mx-2 mt-1 mb-3" id="exampleFormControlTextarea1" name="response" rows="3" required minLength={30}></textarea>
        <Button className="col-8 mt-2" content="Send" />
      </form></div>}



  </div>
};
export default QueryCard;