import { configureStore } from "@reduxjs/toolkit";
import userReducer from '../slice/userSlice'
import themeReducer from "../redux/reducers/ThemeReducer"

export const store = configureStore({
    reducer: {
        userProvider: userReducer,
        ThemeReducer: themeReducer

    },

    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    serializableCheck: false
})