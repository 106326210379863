import Button from "../components/button/button";
// import "../components/topnav/topnav.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ApiClient from "../app/axiosClient";
import PrizeDistribution from "../components/PrizeDistribution";

const AddContest = () => {
  const router = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dis, setDis] = useState([
    {
      key: 1,
      start: 1,
      end: null,
      amount: null,
    },
  ]);
  const onSubmit = (data) => {
    data.preventDefault();
    if (dis.at(0).amount === null || dis.at(0).end === null || dis.at(0).end === '' || dis.at(0).amount === null) {
      alert("Please Provide correct Prize Distribution.");
      return;
    }
    const endDate = new Date(data.target.endTime.value);
    endDate.setUTCHours(15,30,0,0)
    const body = {
      name: data.target.name.value,
      joiningFee: data.target.joiningFee.value,
      startTime: data.target.startTime.value,
      endTime: endDate,
      limit: data.target.limit.value,
      prizeDistribution: dis
    };

    if (body.startTime > body.endTime) {
      console.log("Incorrect start time");
      return;
    }
    setLoading(true);
    ApiClient.post("/contest/add", body).then((e) => {
      console.log(e.data);
    });
    setLoading(false);
    router(-1);
    console.log(body);
  };

  return (
    loading ? <>Loading</> : <div>
      <div className="row justify-content-between px-3 mt-5">
        <h2 className="col-5 page-header title ">Add Contest</h2>
        <div
          className="col-2 text-end"
          onClick={() => {
            router(-1);
          }}
        >
          <Button content="Back" />
        </div>
      </div>
      <div className="col-12">
        <form onSubmit={onSubmit}>
          <div className="">
            <div className="col-md-6 col-12 ">
              <label htmlFor="">Title</label>
              <input
                className="textfield"
                type="text"
                placeholder="Enter name"
                name="name"
                required
              />
            </div>
            <div className="flex">
              <div className="col-5">
                <label htmlFor="">Joining Fee</label>
                <input
                  className="textfield"
                  type="number"
                  placeholder="Enter fee"
                  required
                  name="joiningFee"
                />
              </div>
              <div className="col-5 col-md-3">
                <label htmlFor="">Limit</label>
                <input
                  className="textfield"
                  type="number"
                  placeholder="Enter limit"
                  required
                  name="limit"
                />
              </div>
            </div>
            <div className="flex">
              <div className="col-md-4 col-6">
                <label htmlFor="">Start Time</label>
                <input
                  className="textfield"
                  type="date"
                  placeholder="Enter fee"
                  required
                  name="startTime"
                />
              </div>
              <div className="col-md-4 col-6">
                <label htmlFor="">End Time</label>
                <input
                  className="textfield"
                  type="date"
                  placeholder="Enter limit"
                  required
                  name="endTime"
                />
              </div>
            </div>
            <h4 className="col-12  mb-3 mt-1 mb-0">Prize Distribution</h4>
            {dis.map((e, i) => (
              <PrizeDistribution
                key={i}
                start={e.start}
                handleChang={(e) => {
                  const tempData = dis;


                  tempData.at(i)[e.target.name] = parseInt(e.target.value)
                  console.log(tempData.at(i).end);
                  setDis(tempData)
                }}
              />
            ))}
            <div
              className="add-more-rank mb-3 mt-0 row mx-4"
              onClick={() => {
                console.log(dis);
                const lastElem = dis.at(dis.length - 1);
                console.log(lastElem.end);
                if ((lastElem.end < lastElem.start) || lastElem.end == null) {
                  alert("Invalid end Position")
                }
                else if (lastElem.amount == null) {
                  alert("Invalid Prize Amount")
                }
                else {
                  setDis([
                    ...dis,
                    {
                      start: lastElem.end + 1,
                    },
                  ]);
                }
              }}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "#400835",
              }}
            >
              Add
            </div>
            <Button className="col-8" content="Add contest" />
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddContest;
