import React, { useEffect, useState } from "react";
import Table from "../components/table/Table";
import { Link, useParams } from "react-router-dom";
import User from "./User";
import ApiClient from "../app/axiosClient";
import { AiFillEdit } from "react-icons/ai";

const customerTableHead = [
  "id",
  "name",
  "email",
  "phone",

  "Created At",
  "Action",
];
export const renderHead = (item, index) => <th key={index}>{item}</th>;

export const renderBody = (item, index) => (
  <tr key={index}>
    <td>{item._id.substring(4).toUpperCase()}</td>
    <td>

      <Link to={`/customers/${item._id}`}> {item.name}</Link>
    </td>
    <td>{item.email}</td>
    <td>{item.phone}</td>

    <td>{item.createdAt}</td>

    <td>

      <Link to={`/customers/${item._id}`}>
        <AiFillEdit />
      </Link>
    </td>
  </tr>
);
const Customers = () => {
  const [users, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  async function getContest() {
    ApiClient.get(`/customers?page=0`).then((e) => {
      console.log(e.data.data);
      setData(e.data.data);
      setLoading(false);
    });
  }
  useEffect(() => {
    getContest();
    return () => {
    };
  }, []);

  return loading ? (
    <>Loading</>
  ) : (
    <div>
      {params.id != null ? (
        <User />
      ) : (
        <div className="p-md-5 p-2" >
          {" "}
          <h2 className="page-header">customers</h2>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card__body">
                  <Table
                    limit="10"
                    headData={customerTableHead}
                    renderHead={(item, index) => renderHead(item, index)}
                    bodyData={users}
                    renderBody={(item, index) => renderBody(item, index)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Customers;
