import React, { useEffect } from "react";
import "./layout.css";
import Sidebar from "../sidebar/Sidebar";
import TopNav from "../topnav/TopNav";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ThemeAction from "../../redux/actions/ThemeAction";
import Dashboard from "../../pages/Dashboard";
import Customers from "../../pages/Customers";
import AddContest from "../../pages/AddContests";
import Contests from "../../pages/Contests";
import Helpdesk from "../../pages/Helpdesk";
import History from "../../pages/History";
import EditContest from "../../pages/EditContest";
import Profile from "../../pages/profile";
import NotificationSection from "../../pages/Notification";


const Layout = () => {
  const themeReducer = useSelector((state) => state.ThemeReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    const themeClass = localStorage.getItem("themeMode", "theme-mode-dark");
    const colorClass = localStorage.getItem("colorMode", "theme-mode-dark");
    dispatch(ThemeAction.setMode(themeClass));
    dispatch(ThemeAction.setColor(colorClass));
  }, [dispatch]);
  return (
    <div className={`layout ${themeReducer.mode} ${themeReducer.color}`}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<CustomLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="customers/:id" element={<Customers />}></Route>
            <Route path=":customers" element={<Customers />} />
            <Route path="contests" element={<Contests />} />
            <Route path="profile" element={<Profile />} />
            <Route path="contest/edit/:id" element={<EditContest />} />
            <Route path="addContest" element={<AddContest />} />
            <Route path="notification" element={<NotificationSection />} />
            <Route path="wallet/:id" element={<History />}>
            </Route>
            <Route path="helpdesk" element={<Helpdesk />}>
            </Route>
            <Route path=":id" element={<History />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Layout;
const CustomLayout = () => {
  return (
    <div className=" row">
      <div className="col-12 col-md-2 col-sm-12 col-xs-3 mobile-nav p-0">
        <Sidebar />
      </div>
      <div className="col-12 col-md-10 col-sm-12 p-3 m-0 p-md-0 ">
        <div className="top-nav">

          <TopNav />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

